import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FieldValues } from "react-hook-form";
import type { CoreFieldProps } from "./types";

type InputProps<T extends FieldValues> = CoreFieldProps<T> & {
  inputGroupText?: string;
  children: React.ReactNode;
};

export default function Field<T extends FieldValues>({
  name,
  label = "",
  hideLabel = false,
  helpText = "",
  inputGroupText = "",
  required = true,
  isDirty = undefined,
  error = undefined,
  resetField = undefined,
  className = "mb-3",
  children,
}: InputProps<T>) {
  return (
    <Form.Group controlId={name} className={className}>
      {hideLabel === false && <Form.Label>{label} {required !== true && <span className="text-muted">(optional)</span>}</Form.Label>}
      <InputGroup className="has-validation">
        {children}
        {resetField !== undefined && 
          <Button
            variant="outline-secondary"
            onClick={() => resetField(name)}
            disabled={isDirty === false}
          >
            Reset
          </Button>
        }
        {error?.message !== undefined &&
          <Form.Control.Feedback type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        }
        {inputGroupText !== "" &&
          <InputGroup.Text>{inputGroupText}</InputGroup.Text> 
        }
      </InputGroup>
      {helpText !== undefined &&
        <Form.Text id={`${name}HelpText`} muted>
          {helpText}
        </Form.Text>
      }
    </Form.Group>
  );
}