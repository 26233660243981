import { useContext, useCallback, useEffect } from "react";

import { ModalContext } from "@evos/components";

import { BsArrowRepeat } from "react-icons/bs";

import type { AuthProps } from "lib/context/auth";

type GlobalNotificationModalProps = {
  user: AuthProps["user"] | undefined;
  expiredTokenError: boolean | undefined;
  supportedBrowsers: RegExp;
};

export default function GlobalNotificationModal({
  user,
  expiredTokenError,
  supportedBrowsers
}: GlobalNotificationModalProps) {
  const { showModal, handleModal } = useContext(ModalContext);

  const handleNotificationModals = useCallback((type: string) => {
    switch (type) {
    case "tokenExpired":
      handleModal({
        content: "Your session has expired. Please refresh the page to login again.",
        title: "Session Expired",
        size: "md",
        confirmButtonText: <><BsArrowRepeat className="me-1"/>Refresh</>,
        enableCloseButton: false,
        handleConfirm: () => {
          location.pathname.includes("authorization")
            ? window.location.replace("/")
            : window.location.reload();
        }
      });
      break;  
    case "unsupportedBrowser":
      handleModal({
        content: "Your browser is not supported. Please update your current browser to the latest version or use a different browser.",
        title: "Unsupported Browser",
        size: "md",
        enableCloseButton: false,
      });
      break;
    }
  }, [handleModal]);

  useEffect(() => {
    if (expiredTokenError === true && showModal === false) {
      handleNotificationModals("tokenExpired");
    }
  }, [expiredTokenError, handleNotificationModals, showModal]);

  useEffect(() => {
    if (supportedBrowsers.test(navigator.userAgent) === false && showModal === false) {
      handleNotificationModals("unsupportedBrowser");
    }
  }, [handleNotificationModals, showModal, supportedBrowsers]);

  return(null);
}