import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
  backgroundFill?: string;
  contentFill?: string;
  enableBorder?: boolean;
}

const SvgVid = ({
  title = "Vehicle Integration Device",
  titleId = "vid-icon",
  backgroundFill = "#082ace",
  contentFill = "#fff",
  enableBorder = true,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 204.2 204.2"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g data-name="Layer 1">
      <circle
        cx={102.1}
        cy={102.1}
        r={90.3}
        style={{
          fill: backgroundFill
        }}
      />
      { enableBorder 
        && <path
          d="M102.1 204.2C45.8 204.2 0 158.4 0 102.1S45.8 0 102.1 0s102.1 45.8 102.1 102.1-45.8 102.1-102.1 102.1Zm0-192.4c-49.8 0-90.3 40.5-90.3 90.3s40.5 90.3 90.3 90.3 90.3-40.5 90.3-90.3-40.5-90.3-90.3-90.3Z"
          style={{
            fill: "#b1b3b9",
          }}
        />
      }
      <path
        d="M158.2 140.3H45.3c-7.6 0-13.7-6.1-13.7-13.7V78.7c0-7.6 6.1-13.7 13.7-13.7h112.9c7.6 0 13.7 6.1 13.7 13.7v47.9c0 7.5-6.2 13.7-13.7 13.7Z"
        style={{
          fill: contentFill,
        }}
      />
      <path
        d="M150.1 131H54.2c-2.8 0-5.4-1.2-7-3.3-1.4-1.8-1.8-4.1-1.3-6.2l10.4-38.2c.9-3.5 4.3-5.9 8.3-5.9h75.2c3.9 0 7.3 2.4 8.3 5.9l10.4 38.2c.6 2.1.1 4.4-1.3 6.2-1.7 2-4.3 3.3-7.1 3.3Z"
        style={{
          fill: backgroundFill
        }}
      />
      <path
        d="M111.6 77.8H90.1c-.8 0-1.4-.6-1.4-1.4v-2.3c0-.8.6-1.4 1.4-1.4h21.5c.8 0 1.4.6 1.4 1.4v2.3c0 .7-.6 1.4-1.4 1.4ZM144.4 104.4H57.6c-.7 0-1.4-.6-1.4-1.4v-.7c0-.7.6-1.4 1.4-1.4h86.8c.7 0 1.4.6 1.4 1.4v.7c-.1.8-.7 1.4-1.4 1.4Z"
        style={{
          fill: "#b1b3b9",
        }}
      />
      <path
        d="M75.8 91.9h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM65.5 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c.1.5-.3.9-.9.9ZM75.8 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM86.9 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM97 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9H97c.5 0 .9.4.9.9v1c.1.5-.4.9-.9.9ZM107.3 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM118.4 116.4h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM129.2 116.4H126c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM86.9 91.9h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM118.4 91.9h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c0 .5-.4.9-.9.9ZM128.6 91.9h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c.1.5-.4.9-.9.9ZM139.6 91.9h-3.2c-.5 0-.9-.4-.9-.9v-1c0-.5.4-.9.9-.9h3.2c.5 0 .9.4.9.9v1c.1.5-.4.9-.9.9Z"
        style={{
          fill: contentFill,
        }}
      />
    </g>
  </svg>
);

export default SvgVid;
