import Form from "react-bootstrap/Form";
import Field from "../Field";
import { FieldValues, UseFormRegister } from "react-hook-form";
import type { CoreFieldProps } from "../types";

interface InputProps<T extends FieldValues> extends CoreFieldProps<T> {
  options: Array<{ label: string, value: string, disabled?: boolean }>;
  register: UseFormRegister<T>;
}

export default function SelectField<T extends FieldValues>(props: InputProps<T>) {
  const {
    name,
    options = [],
    disabled = false,
    isSubmitted = false,
    isValid = undefined,
    isInvalid = undefined,
    register,
    getFieldState,
  } = props;

  return (
    <Field {...props}>
      <Form.Select
        {...register(name)}
        isValid={isValid !== undefined ? isValid : isSubmitted === true && getFieldState(name)?.invalid === false}
        isInvalid={isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true}
        aria-describedby={`${name}HelpText`} // This links the help text to the input field for screen readers
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>{option.label}</option>
        ))}
      </Form.Select>
    </Field>
  );
}