import { Component } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import { Alert } from "@evos/components";

import logo from "./assets/svg/EVOSLogo.svg";

import type { ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State extends ErrorInfo {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null, componentStack: ""};
  }

  public componentDidCatch(error: Error | null, errorInfo: ErrorInfo): State {
    this.setState({
      hasError: true,
      error: error,
      componentStack: errorInfo.componentStack
    });

    if ((error != null) && error.toString().includes("chunk")) { 
      const pageRefreshedFromError: boolean = JSON.parse(
        window.sessionStorage.getItem(
          "errorRefresh"
        ) ?? "false"
      );
      if (!pageRefreshedFromError) {
        window.sessionStorage.setItem("errorRefresh", "true");
        window.location.reload();
      }
    }
    
    return {
      hasError: true,
      error: error,
      componentStack: errorInfo.componentStack
    };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div id="ErrorBoundary">
          <Container fluid>
            <Row className="justify-content-center">
              <Col className="mt-4" lg={6}>
                <Image src={logo} height="60" style={{marginLeft: "-14px"}} />
                <Alert 
                  variant="danger" 
                  className="mt-3"
                  heading="Something went wrong"
                  message={
                    <>
                      <p>Please try <a href="/">reloading the app.</a></p>
                      <p>If the issue persists, please contact EVOS Support (Phone <a href="tel:+61 7 3543 0064">+61 7 3543 0064</a>)</p>
                      <details style={{ whiteSpace: "pre-wrap" }}>
                        <>
                          {(this.state.error != null) && this.state.error.toString()}
                          <br />
                          {this.state.componentStack}
                        </>
                      </details>
                    </>
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;