import { useState } from "react";

import {
  BsInfoSquareFill,
  BsExclamationTriangleFill,
  BsCheckSquareFill,
  BsXLg
} from "react-icons/bs";

import type { ReactNode, CSSProperties } from "react";

import "./alert.css";
import ConditionalWrapper from "../ConditionalWrapper";

type AlertProps = {
  variant: "success" | "danger" | "warning" | "info";
  className?: string;
  style?: CSSProperties;
  showIcon?: boolean;
  heading?: string;
  message: string | ReactNode;
  action?: ReactNode;
  dismissible?: boolean;
  role?: "alert" | "status" | "note";
  onDismiss?: () => void;
}

export default function Alert({
  variant, 
  className = "", 
  style,
  showIcon = false, 
  heading, 
  message, 
  action, 
  dismissible = false,
  role = "alert",
  onDismiss
}: AlertProps) {

  const iconVariant = (variant: string) => {
    switch (variant) {
    case "info":
      return <BsInfoSquareFill color="#001DD2" style={{marginBottom: "3px"}} />;
    case "warning":
      return <BsExclamationTriangleFill color="#FFD336" style={{marginBottom: "3px"}} />;
    case "danger":
      return <BsExclamationTriangleFill color="#F03837" style={{marginBottom: "3px"}} />;
    case "success":
      return <BsCheckSquareFill color="#3CC13B" style={{marginBottom: "3px"}} />;
    default:
      return undefined;
    }
  };

  const [ unmountAlert, setUnmountAlert ] = useState(false);
  const [ opacityAnimation, setOpacityAnimation ] = useState(false);

  const handleDismiss = (onDismiss?: () => void) => {
    if (onDismiss != null) {
      onDismiss();
    }
    setOpacityAnimation(true);
    setTimeout(() => {
      setUnmountAlert(true);
    }, 400);
  };

  return (
    unmountAlert
      ? null
      : <div 
        className={`
          alert 
          alert-${variant} 
          ${className} 
          ${dismissible ? "alert-dismissable pe-4" : ""} 
          ${opacityAnimation ? "alert-fade-out" : ""}
        `} 
        role="alert"
        style={style} 
      >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div>
            {heading != null && <h4>{heading}</h4>}
            <ConditionalWrapper
              condition={typeof message === "string"}
              wrapper={(children) => <span className="mb-0">{children}</span>}
            >
              {showIcon && <span className="me-2" data-testid="icon">{iconVariant(variant)}</span>}
              {message}
            </ConditionalWrapper>
          </div>
          {dismissible && <button className="alert-btn-close" type="button" aria-label="Close" onClick={() => handleDismiss(onDismiss)}><BsXLg size={20} /></button>}
        </div>
        {action != null && <div className="pt-3">{action}</div>}
      </div>
  );
}