import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/react";

import ErrorBoundary from "./ErrorBoundary";
import Providers from "Providers";

import App from "./App";

import "./index.scss";
import "./assets/scss/typography.scss";
import "./assets/scss/custom-bootstrap.scss";
import "./assets/scss/animations.scss";

if ((process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") && !window.location.host.includes(".uat.")) { 
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tunnel: `${process.env.REACT_APP_GRAPHQL_API_URL}/monitor`,
    transportOptions: {
      fetchOptions: {
        credentials: "include",
      }
    },
    release: process.env.REACT_APP_CI_COMMIT_SHA,
    environment: process.env.REACT_APP_ENV,
    tracePropagationTargets: [/[A-Za-z]+\.evos\.app/i],
    integrations: [
      captureConsoleIntegration({
        levels: ["error"]
      })
    ],
  
    // Capture 100% of transactions
    tracesSampleRate: 1,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </ErrorBoundary>
  // </React.StrictMode>
);