export default function ConditionalWrapper(
  {
    condition,
    wrapper,
    children
  }: {
    condition: boolean,
    wrapper: (children: React.ReactNode) => JSX.Element,
    children: React.ReactNode | React.ReactNode[]
  }
) {
  return condition === true
    ? wrapper(children)
    : <>{children}</>;  
}
