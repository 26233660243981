import Form from "react-bootstrap/Form";
import Field from "../Field";
import { FieldValues, UseFormRegister } from "react-hook-form";
import type { CoreFieldProps } from "../types";

interface TextAreaProps {
  type: "textarea";
  rows?: number;
  cols?: number;
  wrap?: "hard" | "soft";
  maxLength?: number;
}

interface OtherTypesProps {
  type?:
    "text" |
    "textarea" |
    "email" |
    "password" |
    "number" |
    "tel" |
    "url" |
    "search" |
    "date" |
    "time" |
    "datetime-local" |
    "month" |
    "week" |
    "hidden";
}

type InputProps<T extends FieldValues> = CoreFieldProps<T> & (TextAreaProps | OtherTypesProps) & {
  placeholder?: string;
  defaultValue?: string;
  register: UseFormRegister<T>;
  registerOptions?: Parameters<UseFormRegister<T>>[1];
};

export default function InputField<T extends FieldValues>(props: InputProps<T>) {
  const {
    name,
    label = "",
    hideLabel = false,
    placeholder = "",
    type = "text",
    required = true,
    disabled = false,
    isSubmitted = false,
    isValid = undefined,
    isInvalid = undefined,
    defaultValue = "",
    register,
    registerOptions,
    getFieldState,
  } = props;

  return (
    <Field {...props} hideLabel={hideLabel}>
      <Form.Control
        type={type}
        placeholder={(placeholder === "" ? label : placeholder) + (required !== true ? " (optional)" : "")}
        {...register(name, registerOptions)}
        defaultValue={defaultValue}
        isValid={isValid !== undefined ? isValid : isSubmitted === true && getFieldState(name)?.invalid === false}
        isInvalid={isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true}
        aria-describedby={`${name}HelpText`} // This links the help text to the input field for screen readers
        disabled={disabled}
        {...(type === "textarea" && {
          as: "textarea",
          rows: (props as TextAreaProps).rows,
          cols: (props as TextAreaProps).cols,
          wrap: (props as TextAreaProps).wrap,
          maxLength: (props as TextAreaProps).maxLength
        })}
      />
    </Field>
  );
  
}