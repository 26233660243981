const {
  REACT_APP_GRAPHQL_API_URL: GRAPHQL_API_URL,
  REACT_APP_DRIVER_LOGIN_URL: DRIVER_LOGIN_URL,
  REACT_APP_FLEET_LOGIN_URL: FLEET_LOGIN_URL,
  REACT_APP_INSTALLER_LOGIN_URL: INSTALLER_LOGIN_URL,
  REACT_APP_UNIVERSAL_LOGIN_URL: UNIVERSAL_LOGIN_URL
} = process.env;

export const login_url = (user_type?: string) => {
  if (user_type === "Driver") return DRIVER_LOGIN_URL;
  if (user_type === "Installer") return INSTALLER_LOGIN_URL;
  if (user_type === "Fleet_Manager") return FLEET_LOGIN_URL;
  return UNIVERSAL_LOGIN_URL;
};

export const register_url = (user_type: string) =>
  `${login_url(user_type)}/s/login/SelfRegister`;

export const checkAuth = async () =>
  fetch(
    `${GRAPHQL_API_URL}/check-auth`,
    {
      method: "GET",
      credentials: "include"
    }
  ).then((data) => data.json());

export const login = async (username: string, password: string) =>
  fetch(
    `${GRAPHQL_API_URL}/login-headless?userType=universal`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    }
  ).then((data) => data.json());

export const initiateTokens = async (controller: AbortController, authCode: string) =>
  fetch(
    `${GRAPHQL_API_URL}/tokens?userType=universal&authCode=${authCode}`,
    {
      method: "POST",
      credentials: "include",
      signal: controller.signal
    }
  ).then((data) => data.json());

export const signOut = async () =>
  fetch(
    `${GRAPHQL_API_URL}/signout`,
    {
      method: "GET",
      credentials: "include"
    }
  ).then((data) => data.json());