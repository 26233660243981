import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  ApolloLink,
} from "@apollo/client";
import { getMainDefinition, relayStylePagination } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

import SerializingLink from "apollo-link-serialize";
import { SubscriptionClient } from "subscriptions-transport-ws";

import onlineCheck from "lib/functions/onlineCheck";
import QueueMutationsLink from "lib/api/QueueMutationsLink";

import type { AuthProps } from "../context/auth";

export default async function graphqlAuthSetup (auth: AuthProps | null) {
  const online = await onlineCheck();

  // queueLink is a custom link that queues mutations when offline
  // serializingLink is a custom link that serializes mutations so that they are sent in order
  // retryLink is a custom link that retries mutations that fail due to network errors
  const queueLink = new QueueMutationsLink();
  const serializingLink = new SerializingLink();
  const retryLink = new RetryLink({attempts: {max: 3}});

  const httpLink = createHttpLink({
    uri: operation => {
      if (operation?.getContext()?.isPublic === true) {
        return `${process.env.REACT_APP_GRAPHQL_PUBLIC_API_URL}`;
      } else {
        return `${process.env.REACT_APP_GRAPHQL_API_URL}/api`;
      }
    },
    credentials: process.env.REACT_ENV === "production" ? "same-origin" : "include"
  });

  const wsClient = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_URL!, {
    reconnect: true,
    minTimeout: 10000,
    lazy: true,
  });

  const wsLink = new WebSocketLink(wsClient);

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      }
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chargers: relayStylePagination(
            (args, { field, variables }) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const connectionName = (field?.directives?.[0]?.arguments?.[0]?.value as any)?.value;
              if (connectionName === "chargerTransactionDetails") {
                return `${connectionName}-${variables?.transactions_where?.transactionId?.eq}`;
              } else if (
                connectionName === "singleCharger" 
                || connectionName === "singleChargerLatestTransaction" 
                || connectionName === "singleChargerTotalEnergyLifetime"
              ) {
                return `${connectionName}-${variables?.where?.serial?.eq ?? variables?.chargers_where?.serial?.eq}`;
              } else {
                return connectionName;
              }
            }
          ),
          users: relayStylePagination(),
          vehicles: relayStylePagination(["@connection", ["key"]]),
        }
      },
      ChargerMonitors: {
        fields: {
          charger_status_messages: relayStylePagination(),
          total_energy: {
            keyArgs: false
          }
        }
      },
      Vehicle: {
        keyFields: (obj ) => {
          if (Object.keys(obj)?.includes("vehicle_transactions:{\"@connection\":{\"key\":\"vehicleChargingSessionsTransactions\"}}")) {
            return []; // This is required to get pagination to work on the vehicle_transactions field
          } else {
            return undefined;
          }
        },
        fields: {
          vehicle_transactions: relayStylePagination(["@connection", ["key"]]),
        },
      },
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError((error) => {
        if ((error as unknown as {networkError: {result: {message: string}}})?.networkError?.result?.message?.toLowerCase().includes("not authenticated")) {
          auth?.setExpiredTokenError(true);
        }
        if (error?.graphQLErrors?.[0]?.message.includes("401") === true) {
          auth?.setExpiredTokenError(true);
        }
      }),
      queueLink,
      serializingLink,
      retryLink,
      authLink.concat(splitLink)
    ]),
    connectToDevTools: true,
    cache
  });

  if (!online) {
    queueLink?.close();
  }

  return { client, wsClient, queueLink};
}