import { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import placeholderImage from "./image-placeholder.png";

import type { ChangeEvent } from "react";
import type { FieldError, FieldValues, Path, RefCallBack, UseFormGetFieldState } from "react-hook-form";

type ImageUploadProps<T extends FieldValues> = {
  name: Path<T>,
  value: File | null,
  onChange: (file: File | null) => void,
  onBlur: () => void,
  forwardRef: RefCallBack,
  required?: boolean
  disabled?: boolean
  isSubmitted: boolean
  isInvalid?: boolean
  error?: FieldError
  getFieldState: UseFormGetFieldState<T>;
}

export default function ImageUpload<T extends FieldValues>({
  name,
  value,
  onChange,
  onBlur,
  forwardRef,
  required,
  disabled,
  isSubmitted = false,
  isInvalid,
  getFieldState,
  error = undefined,
  ...props
}: ImageUploadProps<T>) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement> | null>();
  const [preview, setPreview] = useState("");

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (selectedFile == null) {
      return setPreview("");
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {

    if ((e.target.files == null) || e.target.files.length === 0) {
      return setSelectedFile(undefined);
    }

    onChange(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setEvent(e);
  };

  const handleDiscardSelectedFile = () => {
    if (event != null) {
      event.target.value = "";
    }
    onChange(null);
    setSelectedFile(undefined);
    setPreview("");
  };

  return (
    <>
      <Row className="mb-2">
        <Col>
          { (selectedFile != null)
            ? <Image src={preview} fluid thumbnail style={{maxHeight: "150px"}} />
            : <Image src={placeholderImage} fluid thumbnail style={{maxHeight: "150px"}} />
          }
        </Col>
      </Row>
      <InputGroup hasValidation>
        <Form.Control
          onChange={onSelectFile}
          onBlur={onBlur}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          defaultValue={value as any}
          ref={forwardRef}
          required={required}
          type="file"
          accept="image/jpeg, image/png"
          placeholder="Upload photo..."
          multiple={false}
          isInvalid={isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true}
          // isInvalid={fileSizeError || (fileTypeError !== "") || (required === true && selectedFile === undefined)}
          disabled={disabled}
          {...props}
        />
        { (selectedFile != null) && <Button variant="outline-danger" id="discardPhoto" onClick={() => handleDiscardSelectedFile()}>
          Remove
        </Button>}
        {error?.message !== undefined &&
          <Form.Control.Feedback type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        }
      </InputGroup>

    </>
  );
}