// Based on https://github.com/helfer/apollo-link-queue

import {
  ApolloLink,
} from "@apollo/client/link/core";
import {
  Observable,
} from "@apollo/client/utilities";
import { getMainDefinition } from "@apollo/client/utilities";

import type { Observer } from "@apollo/client/utilities";
import type {
  Operation,
  FetchResult,
  NextLink,
} from "@apollo/client/link/core";

interface OperationQueueEntry {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
  subscription?: { unsubscribe: () => void };
}

export default class QueueMutationsLink extends ApolloLink {
  private mutationQueue: OperationQueueEntry[] = [];
  private isOpen = true;

  public open() {
    this.isOpen = true;
    this.mutationQueue.forEach(({ operation, forward, observer }) => {
      const timeOfExecution = Date.now();
      const timeOfRequest = operation.getContext().timeOfRequest;
      const mutationTimeout = operation.getContext().mutationTimeout;
      
      // If the mutation has been in the queue for less than the timeout, execute it
      if ((timeOfExecution - timeOfRequest) < (mutationTimeout ?? 300000)) { // Default timeout is 5 minutes
        forward(operation).subscribe(observer);
      }
    });
    this.mutationQueue = [];
  }

  public close() {
    this.isOpen = false;
  }

  public request(operation: Operation, forward: NextLink) {
    if (this.isOpen) {
      return forward(operation);
    }
    if (operation.getContext().skipQueue != null) {
      return forward(operation);
    }

    const definition = getMainDefinition(operation.query);
    if (definition.kind === "OperationDefinition" && definition.operation === "query") {
      // Queries are not queued, only mutations
      return forward(operation);
    }

    return new Observable<FetchResult>((observer: Observer<FetchResult>) => {
      const operationEntry = { operation, forward, observer };
      operationEntry.operation.setContext({ timeOfRequest: Date.now() });
      this.enqueue(operationEntry);
      return () => this.cancelOperation(operationEntry);
    });
  }

  private cancelOperation(entry: OperationQueueEntry) {
    this.mutationQueue = this.mutationQueue.filter(e => e !== entry);
  }

  private enqueue(entry: OperationQueueEntry) {
    this.mutationQueue.push(entry);
  }
}