import {
  CloudPlatform1 as CloudIcon
} from "@evos/evos-react-icons";

import "./loading.css";

type LoadingProps = {
  includeSidebar?: boolean;
}

export default function Loading({
  includeSidebar = false
}: LoadingProps = {
}) {

  return (
    <div id="Loading">
      { includeSidebar
        ? <div className="loading-container-grid" style={{position: "relative"}}>
          <div className="loading-grid-sidebar" style={{backgroundColor: "#001DD2", minHeight: "100vh", boxShadow: "1px 1px 7px -4px #737373"}} />
          <div className="loading-grid-icon">
            <CloudIcon 
              data-testid="loading-icon"
              className="loading-icon" 
              fontSize="10em"
            />
          </div>
        </div>
        : <div className="loading-container" style={{position: "relative"}}>
          <CloudIcon 
            data-testid="loading-icon"
            className="loading-icon" 
            fontSize="10em"
          />
        </div>
      }
    </div>
  );
}