import BootstrapCard from "react-bootstrap/Card";

import type { CSSProperties, ReactNode } from "react";

type CardProps = {
  children: ReactNode;
  darkMode: boolean;
  titleText?: string;
  titleElement?: ReactNode;
  style?: CSSProperties;
  cardClassname?: string;
  cardBodyClassname?: string;
}

export default function WidgetCard({ 
  children, 
  darkMode, 
  titleText, 
  titleElement, 
  style, 
  cardClassname = "",
  cardBodyClassname 
}: CardProps) {
  return (
    <BootstrapCard className={`text-start ${darkMode ? "bg-dark" : ""} ${cardClassname}`} style={style}>
      {
        (titleText != null || titleElement != null) && (
          <>
            <div className="d-flex flex-wrap row-gap-2 align-items-center justify-content-between pt-3 ps-3 mb-3">
              <BootstrapCard.Title className="mb-0">{titleText}</BootstrapCard.Title>
              <div className="me-2">{titleElement}</div>
            </div>
            <div style={{borderBottom: "1px solid lightgrey"}} />
          </>
        )
      }
      <BootstrapCard.Body className={cardBodyClassname}>
        {children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
}