/* eslint-disable react/no-array-index-key */
type SkeletonProps = {
  height: string | number;
  width?: (string | number) | Array<string | number>;
  borderRadius?: string;
  outerClassName?: string;
  innerClassName?: string;
  innerStyle?: React.CSSProperties;
  innerGap?: string;
  lines?: number;
}

import "./skeleton.css";

export default function Skeleton({
  height,
  width = "100%",
  borderRadius = "10px",
  outerClassName,
  innerClassName = "",
  innerStyle,
  innerGap = "0px",
  lines = 1,
}: SkeletonProps) {
  return (
    <>
      { (outerClassName != null) 
        ? [...Array(lines)].map((_, i) => (
          <div className={outerClassName} key={`outer-line-${i}`}>
            <div 
              data-testid="skeleton"
              className={`skeleton-shine ${innerClassName}`} 
              style={{height: height, width: Array.isArray(width) ? width[i] : width, marginRight: innerGap, marginLeft: innerGap, borderRadius: borderRadius, ...innerStyle}} 
            />
          </div>
        ))
        : [...Array(lines)].map((_, i) => (
          <div 
            key={`inner-line-${i}`} 
            data-testid="skeleton"
            className={`skeleton-shine ${innerClassName}`} 
            style={{height: height, width: Array.isArray(width) ? width[i] : width, marginRight: innerGap, marginLeft: innerGap, borderRadius: borderRadius, ...innerStyle}}
          />
        ))
      }
    </>
  );
}