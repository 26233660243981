import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import { BsChevronDown, BsCircleFill } from "react-icons/bs";
import { Controller } from "react-hook-form";

import "./checkboxDropdown.css";

import type { Control } from "react-hook-form";

type CheckboxDropdownMenuProps = {
  displayTitle: string;
  items: string[];
  // handleChecked: (title: string, field_name: string, checked: boolean) => void;
  dataTitle: string;
  checkedState: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any> | undefined
  isRadio?: boolean;
}

export default function CheckboxDropdownMenu({
  displayTitle, 
  items, 
  // handleChecked,
  dataTitle, 
  checkedState, 
  formControl,
  isRadio = false
}: CheckboxDropdownMenuProps) {

  return (
    <Dropdown id="CheckboxDropdownMenu" className="checkbox-dropdown pe-2" autoClose="outside">
      <Dropdown.Toggle as="div" id="dropdown-toggle" data-testid="dropdown-toggle" style={{cursor: "pointer"}}>
        <span><b>{displayTitle}</b>
          {checkedState?.length > 0 
            ? <span className="ms-2" style={{position: "relative"}}>
              <BsCircleFill color="#001DD2" />
              <span className="filter-icon-number text-light fw-medium">
                {checkedState.length}
              </span>
            </span>
            : ""
          } 
          <BsChevronDown className="ms-2" color="#001DD2"/>
        </span>
      </Dropdown.Toggle>
      {  formControl != null
        ?  <Controller
          control={formControl}
          name={dataTitle}
          render={({
            field: { 
              onChange, 
              onBlur,
              value
            },
          }) => (
            <Dropdown.Menu>
              {items?.map((i, idx) =>
                <Dropdown.Item key={i} as={Form.Group}>
                  <Form.Check
                    type={isRadio ? "radio" : "checkbox"}
                    id={`checkbox-${i}`}
                    checked={value?.includes(i)}
                    name={displayTitle}
                    label={i}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        if (isRadio) {
                          onChange([i]);
                        } else {
                          onChange([...value, i]);
                        }
                      } else {
                        onChange(value.filter((item: string) => item !== i));
                      }
                    }}
                    onBlur={onBlur}
                  />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          )} 
        />
        : <Dropdown.Menu>
          {items?.map((i, idx) =>
            <Dropdown.Item key={i} as={Form.Group}>
              <Form.Check
                type={isRadio ? "radio" : "checkbox"}
                id={`checkbox-${i}`}
                name={displayTitle}
                label={i}
              />
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      }
     
    </Dropdown>
  );
}