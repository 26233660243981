import { useRef, Fragment } from "react";

import type { CSSProperties, ReactNode } from "react";

import Button from "../Button";

type ToggleButtonsProps = {
  name: string
  type: "radio" | "checkbox"
  buttonIcons?: readonly ReactNode[]
  buttonTexts: readonly string[]
  values?: readonly string[]
  defaultValue: string | string[]
  variant?: "primary" | "secondary" | "success" | "warning" | "danger" | "outline-primary" | "outline-secondary" | "outline-success" | "outline-warning" | "outline-danger"
  style?: CSSProperties
  className?: string
  size?: "sm" | "lg"
  disabled?: boolean[] | boolean
  toggleButtonHandler: (e: string) => void
  parentInputRef?: React.RefObject<HTMLInputElement[]>
}

import "./toggleButtons.css";

export default function ToggleButtons({
  name,
  type = "radio",
  buttonIcons, 
  buttonTexts, 
  values = buttonTexts,
  defaultValue, 
  variant = "primary", 
  style, 
  className, 
  size = "lg",
  disabled = false, 
  toggleButtonHandler,
  parentInputRef
}: ToggleButtonsProps) {

  const inputRef = useRef<HTMLInputElement[]>([]);

  const onInputChangeHandler = (e: string) => {
    if (type === "radio") {
      toggleButtonHandler(e);
    } else {
      if (parentInputRef?.current != null) {
        const checked = parentInputRef.current.filter((input) => input.checked).map((input) => input.value);
        toggleButtonHandler(checked.join(", "));
      } else {
        const checked = inputRef.current.filter((input) => input.checked).map((input) => input.value);
        toggleButtonHandler(checked.join(", "));
      }
    }
  };

  if (type === "radio" && Array.isArray(defaultValue) && defaultValue.length > 1) {
    console.warn("Cannot pass an array of default values for radio buttons. Defaulting to the first value.");
  }

  return (
    <div 
      className={`evos-btn-group ${className != null ? className : ""}`} 
      style={style}
      role="group"
    >
      { values.map((value, index) => (
        <Fragment key={value}>
          <input
            name={name}
            type={type}
            value={value}
            className="evos-btn-check"
            ref={(el) => { 
              if (el != null) {
                if (parentInputRef?.current != null) {
                  parentInputRef.current[index] = el;
                } else {
                  inputRef.current[index] = el; 
                }
              }
            }}
            autoComplete="off"
            disabled={typeof disabled === "boolean" ? disabled : (disabled[index] ?? false)}
            defaultChecked={Array.isArray(defaultValue) && type === "checkbox"
              ? defaultValue.includes(value)
              : Array.isArray(defaultValue)
                ? defaultValue[0] === value
                : defaultValue === value
            }
            onChange={(e) => onInputChangeHandler(e.target.value)}
            id={value}
          />
          <Button 
            htmlFor={value}
            variant={variant} 
            disabled={typeof disabled === "boolean" ? disabled : (disabled[index] ?? false)}
          >
            <span className={`evos-btn-check-content ${size}`}>{ (buttonIcons != null) ? buttonIcons[index] : ""} <h3 style={{fontSize: "1em"}}>{buttonTexts[index]}</h3></span>
          </Button>
        </Fragment>
      ))}
    </div>
  );
}