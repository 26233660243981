import { ReactNode } from "react";
import { createContext } from "react";
import useModal, { ModalOptions } from "./useModal";
import Modal from "./Modal";

export interface ModalProviderProps {
  children: ReactNode;
}

interface UseModalReturnTypes {
  showModal: boolean;
  handleModal: (content?: ModalOptions) => void;
  modalOptions: ModalOptions
}

const ModalContext = createContext<UseModalReturnTypes>({} as UseModalReturnTypes);

const ModalProvider = ({ children }: ModalProviderProps) => {
  const { showModal, handleModal, modalOptions } = useModal();
  return (
    <ModalContext.Provider value={{ showModal, handleModal, modalOptions }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };