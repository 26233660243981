import { useContext } from "react";
import { ModalContext } from "./modalContext";

import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import LoadingSpinner from "../LoadingSpinner";

export default function Modal() {
  const { 
    showModal,
    handleModal, 
    modalOptions 
  } = useContext(ModalContext);

  return (
    <BootstrapModal
      show={showModal}
      onHide={() => {
        handleModal();
        if (modalOptions?.handleHide != null) modalOptions.handleHide();
      }}
      backdrop={modalOptions?.enableCloseOnBackdrop === false ? "static" : true}
      size={modalOptions?.size === "md" ? undefined : modalOptions?.size} // BootstrapModal doesn't accept "md" as a size
    >
      <BootstrapModal.Header closeButton={modalOptions?.enableCloseButton ?? true}>
        <BootstrapModal.Title>{modalOptions?.title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{modalOptions?.content}</BootstrapModal.Body>
      {
        (modalOptions?.confirmButtonText != null || modalOptions?.cancelButtonText != null) &&
        <BootstrapModal.Footer>
          { modalOptions?.cancelButtonText != null
            && <Button variant="secondary" onClick={() => {
              if (modalOptions?.handleCancel != null) {
                modalOptions.handleCancel();
              } else {
                handleModal();
              }
            }}>
              { modalOptions?.cancelButtonText }
            </Button>
          }
          { modalOptions?.confirmButtonText != null
            && <Button type="submit" variant={modalOptions?.confirmButtonVariant} disabled={modalOptions.confirmButtonLoading ?? false} onClick={() => {
              handleModal();
              if (modalOptions?.handleConfirm != null) {
                modalOptions.handleConfirm();
              } else {
                handleModal();
              }
            }}>
              {modalOptions.confirmButtonLoading === true
                ? <LoadingSpinner />
                : modalOptions?.confirmButtonText
              }
            </Button>
          }
        </BootstrapModal.Footer>
      }
    </BootstrapModal>
  );
}