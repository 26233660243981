import { createContext } from "react";
import type { Dispatch, SetStateAction } from "react";

interface FilterProps {
  filterSearchParamsString: string,
  setFilterSearchParamsString: Dispatch<SetStateAction<string>>
}

const FilterContext = createContext<FilterProps | null>(null);

export { FilterContext };
export type { FilterProps };